import {lazy} from "react";
import { Navigate } from "react-router-dom";
const AccountSettings = lazy(() => import('../pages/AccountSettings'))
const HomeManagement = lazy(() => import('../pages/HomeManagement'))
const MyOrder = lazy(() => import('../pages/MyOrder'))
// const Interact = lazy(() => import('../pages/Interact'))
const CommonIndexPage = lazy(() => import('../pages/CommonIndexPage'))
const InteractionSummary = lazy(() => import('../pages/InteractionSummary'))
// const InteractManagement = lazy(() => import('../pages/InteractManagement'))
const MyActive = lazy(() => import('../pages/MyActive'))
const Login = lazy(() => import('../pages/Login'))
const Register = lazy(() => import('../pages/Register'))
const Page404 = lazy(() => import('../pages/Page404'))
const Welcome = lazy(() => import('../pages/Welcome'))
const EditActive = lazy(() => import('../pages/EditActive'))
// const Case = lazy(() => import('../pages/Case'))
// const About = lazy(() => import('../pages/About'))
const routes = [
    {
        path: '/',
        element: <Welcome />
    },
    {
        path: 'case',
        element: <Welcome />
    },
    {
        path: 'about',
        element: <Welcome />
    },
    {
        path: 'login',
        element: <Login/>
    },
    {
        path: 'register',
        element: <Register/>
    },
    {
        path: 'home',
        element: <CommonIndexPage/>,
        children: [
            {
                path: '',
                element: <HomeManagement/>
            },
            {
                path: 'manage',
                element: <HomeManagement/>
            },
            {
                path: 'order',
                element: <MyOrder/>
            },
            {
                path: 'setting',
                element: <AccountSettings/>
            },
        ]
    },
    {
        path: 'interact',
        element: <CommonIndexPage/>,
        children: [
            {
                path: '',
                element: <InteractionSummary/>
            },
            {
                path: 'test',
                element: <>test</>
            },
            {
                path: 'test2',
                element: <>test22222</>
            },
            {
                path: 'test3',
                element: <>test33333333</>
            }
        ]
    },
    {
        path: 'i-manage',
        element: <CommonIndexPage />,
        children: [
            {
                path: '',
                element: <Navigate to="my-active" />
            },
            {
                path: 'my-active',
                element: <MyActive/>
            },
            {
                path: 'write-off',
                element: <>核销管理</>
            },
            {
                path: 'user-record',
                element: <>用户记录</>
            }
        ]
    },
    {
        path: 'catalog',
        element: <CommonIndexPage />,
        children: [
            {
                path: '',
                element: <>功能目录</>
            }
        ]
    },
    {
        path: 'api-service',
        element: <CommonIndexPage />,
        children: [
            {
                path: '',
                element: <>接口服务</>
            }
        ]
    },
    {
        path: 'edit-active',
        element: <EditActive />,
    },
    {
        path: '*',
        element: <Page404 />
    }
]
export default routes