import { useEffect } from 'react';
import { Layout } from 'antd';
// import Header from '@/components/Headers';
import { useLocation, useRoutes } from 'react-router-dom';
import routes from '@/routes'
import checkLogin from '@/utils/checkLogin'
import './App.less';

function App() {
  const Routes = useRoutes(routes)
  const location = useLocation()
  useEffect(() => {
    if (checkLogin()) {
      console.log('login')
    } else if (window.location.pathname.replace(/\//g, '') !== 'login'
      && window.location.pathname !== '/'
      && window.location.pathname !== '/case'
      && window.location.pathname !== '/about') {
      // window.location.pathname = '/login';
    }
  },[location])
  return (
    <div className="App">
      <Layout style={{background: '#ffffff', minHeight: '100vh'}}>
        {Routes}
      </Layout>
    </div>
  );
}

export default App;
