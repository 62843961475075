import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { Spin, ConfigProvider } from 'antd';
import { BrowserRouter } from "react-router-dom";
import './index.css'
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import { RecoilRoot } from 'recoil';
import App from './App';
import reportWebVitals from './reportWebVitals';
// 引入公共样式
import 'animate.css';
import './styles/common.less'
import './styles/reset.less'
// 引入媒体查询版心
import './styles/media/index.less'
// 引入flexible
import './utils/flexible'
ReactDOM.render(
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <Suspense fallback={<div className='my_loading'><Spin size="large" tip="加载中..." /></div> }>
          <RecoilRoot>
          <App />
          </RecoilRoot>
        </Suspense>
      </BrowserRouter>
    </ConfigProvider>,
  document.getElementById('root')
);

reportWebVitals();
